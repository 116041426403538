export const buildNavbarMenu = (routes, solution, services) => {
  if (!routes[solution]) {
    return [];
  }
  const toreturn = routes[solution]
    // hide sections that are not enabled at app level
    .filter((i) => {
      return !i.hideSection;
    })

    // hide whole sections that are not enabled for user
    .filter((section) => {
      if (section.alwaysShowSection === true) {
        return true;
      }
      for (const route of section.children) {
        // check if parent is related to a service
        if (!route.service) {
          return true;
        }
        // check if parent has service enabled
        if (route.service && services.includes(route.service)) {
          return true;
        }

        if (route.children) {
          for (const child of route.children) {
            // check if child is related to a service
            if (!child.service) {
              return true;
            } // check if child has service enabled
            if (child.service && services.includes(child.service)) {
              return true;
            }
          }
        }
      }

      return false;
    })

    // handle case where section.children's array of services is disabled and remove them
    .map((section) => {
      // get all children's children
      for (let i = 0; i < section.children.length; i++) {
        let route = section.children[i];

        let children = [];

        if (route.children) {
          route.children.forEach((child) => {
            if (child.service && services.includes(child.service)) {
              children.push(child);
            }
          });
        }

        if (route.children && children.length === 0) {
          //remove route
          section.children.splice(i, 1);
          i--;
        }
      }

      return section;
    });

  return toreturn;
};
