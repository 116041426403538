import axios from 'axios';
import { create } from 'zustand';

import chainData from 'blockscope/static/chainData';
import { CHAINS } from 'blockscope/static/supportedChains';

const STATUS = {
  SEARCH: 'search',
  FOUND: 'found',
  NOT_FOUND: 'not-found',
};

const initBatchRequest = () => ({
  [STATUS.SEARCH]: new Set(),
  [STATUS.FOUND]: new Set(),
  [STATUS.NOT_FOUND]: new Set(),
});

const initState = {
  tokenPrices: {
    NATIVE_TOKEN: new Map(),
    [CHAINS.ETHEREUM]: new Map(),
    [CHAINS.CELO]: new Map(),
    [CHAINS.POLYGON]: new Map(),
    [CHAINS.FANTOM]: new Map(),
    [CHAINS.BASE]: new Map(),
    [CHAINS.ARBITRUM]: new Map(),
    [CHAINS.OPTIMISM]: new Map(),
    [CHAINS.BSC]: new Map(),
    [CHAINS.BLAST]: new Map(),
    [CHAINS.TRON]: new Map(),
    [CHAINS.AVALANCHE_C]: new Map(),
  },
  batchRequestTokens: {
    NATIVE_TOKEN: initBatchRequest(),
    [CHAINS.ETHEREUM]: initBatchRequest(),
    [CHAINS.CELO]: initBatchRequest(),
    [CHAINS.POLYGON]: initBatchRequest(),
    [CHAINS.FANTOM]: initBatchRequest(),
    [CHAINS.BASE]: initBatchRequest(),
    [CHAINS.ARBITRUM]: initBatchRequest(),
    [CHAINS.OPTIMISM]: initBatchRequest(),
    [CHAINS.BSC]: initBatchRequest(),
    [CHAINS.BLAST]: initBatchRequest(),
    [CHAINS.TRON]: initBatchRequest(),
    [CHAINS.AVALANCHE_C]: initBatchRequest(),
  },
  lastRequest: 0,
};

const useTokenPriceStore = create((set, get) => ({
  ...initState,

  fetchPrices: async () => {
    const tokenPrices = get().tokenPrices;
    const batchRequests = get().batchRequestTokens;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    for (const chain in batchRequests) {
      const batchTokens = Array.from(batchRequests[chain][STATUS.SEARCH]);
      if (batchTokens.length > 0) {
        const batchTokensFound = batchRequests[chain][STATUS.FOUND];
        const batchTokensNotFound = batchRequests[chain][STATUS.NOT_FOUND];

        const newTokenPrices = new Map(tokenPrices[chain]);
        const body = {
          coingeckoId:
            chain === 'NATIVE_TOKEN'
              ? batchTokens
              : { chain, addresses: batchTokens },
        };

        try {
          const res = await axios.post(`api/v2/core/token-price`, body, config);
          // set the prices that were found
          for (const address in res.data?.dataPayload?.price) {
            if (res.data?.dataPayload?.price[address].usd) {
              newTokenPrices.set(address, {
                current: res.data?.dataPayload?.price[address].usd,
              });
              batchTokensFound.add(address);
            }
          }
          const notFoundAddresses = batchTokens.filter(
            (address) => !newTokenPrices.has(address)
          );
          notFoundAddresses.forEach((address) => {
            batchTokensNotFound.add(address);
          });
        } catch (err) {
          console.error(err);
        }

        set({
          tokenPrices: {
            ...tokenPrices,
            [chain]: newTokenPrices,
          },
          batchRequestTokens: {
            ...batchRequests,
            [chain]: {
              [STATUS.FOUND]: batchTokensFound,
              [STATUS.NOT_FOUND]: batchTokensNotFound,
              [STATUS.SEARCH]: new Set(),
            },
          },
          lastRequest: Date.now(),
        });
      }
    }
  },
}));

export const addToBatchTokenPriceRequest = (token, chain) => {
  const { batchRequestTokens, tokenPrices } = useTokenPriceStore.getState();
  const lowerCaseAddress = token.toLowerCase();
  if (
    chain in batchRequestTokens &&
    chain in tokenPrices &&
    !batchRequestTokens[chain][STATUS.FOUND].has(lowerCaseAddress) &&
    !batchRequestTokens[chain][STATUS.NOT_FOUND].has(lowerCaseAddress)
  ) {
    batchRequestTokens[chain][STATUS.SEARCH].add(lowerCaseAddress);
  }

  useTokenPriceStore.setState(() => ({ batchRequestTokens }));
};

export const fetchPriceAtTime = async (chain, address, time) => {
  const isNativeToken = chainData.nativeTokens[chain].address === address;

  const tokenPrices = useTokenPriceStore.getState().tokenPrices;
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    coingeckoId: isNativeToken
      ? [chainData.nativeTokens[chain].coingeckoId]
      : { chain, addresses: [address] },
    date: time,
  };
  try {
    const tokenPrice = await axios.post(
      `api/v2/core/token-price`,
      body,
      config
    );

    const tokenChain = isNativeToken ? 'NATIVE_TOKEN' : chain;
    const tokenAddress = isNativeToken
      ? chainData.nativeTokens[chain].coingeckoId
      : address;

    const newPrices = new Map(tokenPrices[tokenChain]);
    const oldPrices = newPrices.get(tokenAddress);

    newPrices.set(tokenAddress, {
      ...oldPrices,
      [time]: tokenPrice.data.dataPayload.price,
    });

    useTokenPriceStore.setState((state) => ({
      tokenPrices: {
        ...state.tokenPrices,
        [tokenChain]: newPrices,
      },
    }));
  } catch (err) {
    console.error(err);
  }
};

export default useTokenPriceStore;
