export const nativeToken = {
  address: '0x0000000000000000000000000000000000000000',
  name: 'Native Avalanche',
  label: 'Native Avalanche',
  value: 'AVAX',
  symbol: 'AVAX',
  tokenType: 'native',
  chain: 'avalanche',
  decimals: 18,
  coingeckoId: 'avalanche-2',
};