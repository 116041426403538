import axios from 'axios';
import { create } from 'zustand';

import { CHAINS } from 'blockscope/static/supportedChains';

const initState = {
  addressLabels: {
    [CHAINS.ETHEREUM]: new Map(),
    [CHAINS.CELO]: new Map(),
    [CHAINS.POLYGON]: new Map(),
    [CHAINS.FANTOM]: new Map(),
    [CHAINS.BASE]: new Map(),
    [CHAINS.ARBITRUM]: new Map(),
    [CHAINS.OPTIMISM]: new Map(),
    [CHAINS.BSC]: new Map(),
    [CHAINS.AVALANCHE_C]: new Map(),
  },
  addressNames: {
    [CHAINS.ETHEREUM]: new Map(),
    [CHAINS.CELO]: new Map(),
    [CHAINS.POLYGON]: new Map(),
    [CHAINS.FANTOM]: new Map(),
    [CHAINS.BASE]: new Map(),
    [CHAINS.ARBITRUM]: new Map(),
    [CHAINS.OPTIMISM]: new Map(),
    [CHAINS.BSC]: new Map(),
    [CHAINS.AVALANCHE_C]: new Map(),
  },
  batchRequestAddresses: {
    [CHAINS.ETHEREUM]: new Set(),
    [CHAINS.CELO]: new Set(),
    [CHAINS.POLYGON]: new Set(),
    [CHAINS.FANTOM]: new Set(),
    [CHAINS.BASE]: new Set(),
    [CHAINS.ARBITRUM]: new Set(),
    [CHAINS.OPTIMISM]: new Set(),
    [CHAINS.BSC]: new Set(),
    [CHAINS.AVALANCHE_C]: new Map(),
  },
  lastRequest: 0,
  fetchAllowed: false,
};

const useLabelsStore = create((set, get) => ({
  ...initState,

  fetchLabels: async () => {
    const isFetchAllowed = get().fetchAllowed;

    if (isFetchAllowed == false) {
      return;
    }
    const batchRequests = get().batchRequestAddresses;
    const addressLabels = get().addressLabels;
    const addressNames = get().addressNames;

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    for (let i = 0; i < Object.keys(batchRequests).length; i++) {
      const chain = Object.keys(batchRequests)[i];

      const batchAddresses = Array.from(batchRequests[chain]);
      if (batchAddresses.length > 0) {
        const body = {
          addresses: batchAddresses,
          chain,
        };
        const newLabels = new Map(addressLabels[chain]);
        const newNames = new Map(addressNames[chain]);
        try {
          const res = await axios.post(`/api/v2/labels/address`, body, config);

          // set the labels that were found
          res.data?.dataPayload?.addressLabels.forEach((label) => {
            newLabels.set(label._id, label.labels);
            if (label.name) {
              newNames.set(label._id, {
                name: label.name,
                sourceCategory: label.sourceCategory,
              });
            }
          });

          // find the addresses that were not found and set them to empty array
          // TODO - This is a hacky way to stop the infinite loop of fetching labels. Must find a way to invalidate the cache.
          const notFoundAddresses = batchAddresses.filter(
            (address) => !newLabels.has(address)
          );
          notFoundAddresses.forEach((address) => {
            newLabels.set(address, []);
          });
        } catch (err) {
          // errorToast('Error fetching labels', LABELS_TOAST_ID.ERROR.FETCH);
        } finally {
          set((state) => ({
            addressLabels: {
              ...state.addressLabels,
              [chain]: newLabels,
            },
            addressNames: {
              ...state.addressNames,
              [chain]: newNames,
            },
            batchRequestAddresses: {
              ...state.batchRequestAddresses,
              [chain]: new Set(),
            },
            lastRequest: Date.now(),
          }));
        }
      }
    }
  },
}));

export const addToBatchAddressLabelRequest = (addresses, chain) => {
  const { batchRequestAddresses, addressLabels } = useLabelsStore.getState();

  addresses.forEach((addr) => {
    if (!addressLabels[chain].has(addr)) {
      batchRequestAddresses[chain].add(addr.toLowerCase());
    }
  });

  useLabelsStore.setState(() => ({
    batchRequestAddresses,
  }));
};

export const getAddressLabels = (address, chain) => {
  const { addressLabels } = useLabelsStore.getState();
  if (!addressLabels[chain]) return [];
  if (!addressLabels[chain].has(address.toLowerCase())) return [];
  return addressLabels[chain].get(address.toLowerCase());
};

export const enableFetchLabels = () => {
  useLabelsStore.setState(() => ({ fetchAllowed: true }));
};
export const disableFetchLabels = () => {
  useLabelsStore.setState(() => ({ fetchAllowed: false }));
};

export default useLabelsStore;
