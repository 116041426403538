import axios from 'axios';
import { create } from 'zustand';

import chainData from 'blockscope/static/chainData';
import { CHAINS } from 'blockscope/static/supportedChains';

const STATUS = {
  SEARCH_DB: 'search-db',
  SEARCH_EXTERNAL: 'search-external',
  FOUND: 'found',
  NOT_IN_DB: 'not-in-db',
  NOT_A_TOKEN: 'not-a-token',
};

const initBatchRequest = () => ({
  [STATUS.SEARCH_DB]: new Set(),
  [STATUS.SEARCH_EXTERNAL]: new Set(),
  [STATUS.FOUND]: new Set(),
  [STATUS.NOT_IN_DB]: new Set(),
  [STATUS.NOT_A_TOKEN]: new Set(),
});

const initState = {
  tokenMetadata: {
    [CHAINS.ETHEREUM]: new Map(),
    [CHAINS.CELO]: new Map(),
    [CHAINS.POLYGON]: new Map(),
    [CHAINS.FANTOM]: new Map(),
    [CHAINS.BASE]: new Map(),
    [CHAINS.ARBITRUM]: new Map(),
    [CHAINS.OPTIMISM]: new Map(),
    [CHAINS.BSC]: new Map(),
    [CHAINS.BLAST]: new Map(),
    [CHAINS.TRON]: new Map(),
    [CHAINS.AVALANCHE_C]: new Map(),
  },
  batchRequestTokens: {
    [CHAINS.ETHEREUM]: initBatchRequest(),
    [CHAINS.CELO]: initBatchRequest(),
    [CHAINS.POLYGON]: initBatchRequest(),
    [CHAINS.FANTOM]: initBatchRequest(),
    [CHAINS.BASE]: initBatchRequest(),
    [CHAINS.ARBITRUM]: initBatchRequest(),
    [CHAINS.OPTIMISM]: initBatchRequest(),
    [CHAINS.BSC]: initBatchRequest(),
    [CHAINS.BLAST]: initBatchRequest(),
    [CHAINS.TRON]: initBatchRequest(),
    [CHAINS.AVALANCHE_C]: initBatchRequest(),
  },
  lastRequest: 0,
};

const useTokenMetaDataStore = create((set, get) => ({
  ...initState,

  fetchTokenData: async (searchExternalData) => {
    const tokenMetadata = get().tokenMetadata;
    const batchRequests = get().batchRequestTokens;

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    for (const chain in batchRequests) {
      // if searchExternalData is true, search the external data batch, otherwise search the db batch
      const batchTokensToSearch = Array.from(
        searchExternalData
          ? batchRequests[chain][STATUS.SEARCH_EXTERNAL]
          : batchRequests[chain][STATUS.SEARCH_DB]
      );

      if (batchTokensToSearch.length > 0) {
        const limitedSearch = [];
        const leftoverSearch = [];

        batchTokensToSearch.forEach((address, index) => {
          if (index < 20) {
            limitedSearch.push(address);
          } else {
            leftoverSearch.push(address);
          }
        });
        const batchTokensFound = batchRequests[chain][STATUS.FOUND];

        // if searchExternalData is true, use the not-a-token batch, otherwise use the not-in-db batch
        const batchTokensNotFound =
          batchRequests[chain][
            searchExternalData ? STATUS.NOT_A_TOKEN : STATUS.NOT_IN_DB
          ];

        const newTokenData = new Map(tokenMetadata[chain]);
        const body = {
          chain,
          tokens: limitedSearch,
          searchExternalData,
        };

        try {
          const res = await axios.post(
            `api/v2/core/token-metadata`,
            body,
            config
          );
          // set the tokens that were found
          const tokens = res.data?.dataPayload?.tokenMetadata || [];
          for (const token of tokens) {
            newTokenData.set(token.address, token);
            batchTokensFound.add(token.address);
          }

          // set the tokens that were not found
          const notFoundAddresses = limitedSearch.filter(
            (address) => !newTokenData.has(address)
          );
          notFoundAddresses.forEach((address) => {
            batchTokensNotFound.add(address);
          });
        } catch (err) {
          console.error(err);
        }
        set({
          tokenMetadata: {
            ...tokenMetadata,
            [chain]: newTokenData,
          },
          batchRequestTokens: {
            ...batchRequests,
            [chain]: {
              ...batchRequests[chain],
              [STATUS.FOUND]: batchTokensFound,
              // if searchExternalData is true, set the not-a-token batch, otherwise set the not-in-db batch
              [searchExternalData ? STATUS.NOT_A_TOKEN : STATUS.NOT_IN_DB]:
                batchTokensNotFound,
              // if searchExternalData is true, clear the external search batch, otherwise clear the db search batch
              [searchExternalData ? STATUS.SEARCH_EXTERNAL : STATUS.SEARCH_DB]:
                new Set(leftoverSearch),
            },
          },
          lastRequest: Date.now(),
        });
      }
    }
  },
}));

export const addToBatchTokenDataRequest = (
  address,
  chain,
  searchExternalData = false
) => {
  const lowerCaseAddress = address.toLowerCase();
  const { batchRequestTokens, tokenMetadata } =
    useTokenMetaDataStore.getState();

  // if the token is already found or confirmed to not be a token, don't add it to the batch request
  if (
    chain in batchRequestTokens &&
    chain in tokenMetadata &&
    !batchRequestTokens[chain][STATUS.FOUND].has(lowerCaseAddress) &&
    !batchRequestTokens[chain][STATUS.NOT_A_TOKEN].has(lowerCaseAddress)
  ) {
    // if the searchExternalData flag is set, add it to the external search batch
    if (searchExternalData) {
      batchRequestTokens[chain][STATUS.SEARCH_EXTERNAL].add(lowerCaseAddress);
    }
    // otherwise, check if it's confirmed to not be in the db, and if not, add it to the db search batch
    else if (
      !batchRequestTokens[chain][STATUS.NOT_IN_DB].has(lowerCaseAddress) &&
      !batchRequestTokens[chain][STATUS.SEARCH_EXTERNAL].has(lowerCaseAddress)
    ) {
      batchRequestTokens[chain][STATUS.SEARCH_DB].add(lowerCaseAddress);
    }
  }

  useTokenMetaDataStore.setState(() => ({ batchRequestTokens }));
};

export const isKnownToken = (token, chain) => {
  const { batchRequestTokens, tokenMetadata } =
    useTokenMetaDataStore.getState();
  return (
    chainData.isNativeToken(token, chain) ||
    (chain in batchRequestTokens &&
      batchRequestTokens[chain][STATUS.FOUND].has(token)) ||
    (chain in tokenMetadata && tokenMetadata[chain].has(token))
  );
};

export const getTokenData = (token = '', chain) => {
  const { tokenMetadata } = useTokenMetaDataStore.getState();
  return tokenMetadata[chain].get(token.toLowerCase());
};

export default useTokenMetaDataStore;
