// Auth Types
export const USER_LOADED = 'USER_LOADED';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_REDUCER_LOADING = 'SET_AUTH_REDUCER_LOADING';
export const SET_AUTH_REDUCER_LOADING_FALSE = 'SET_AUTH_REDUCER_LOADING_FALSE';
export const SET_CONFIRM_EMAIL = 'SET_CONFIRM_EMAIL';
export const USER_INVITES_LOADED = 'USER_INVITES_LOADED';
export const UPDATE_USER_CONFIGS = 'UPDATE_USER_CONFIGS ';

// org
export const GET_ORG_DETAILS = 'GET_ORG_DETAILS';
export const SET_ORG_DETAILS_LOADER = 'SET_ORG_DETAILS_LOADER';
export const GET_ORG_PIPELINES = 'GET_ORG_PIPELINES';
export const SET_ORG_PIPELINES_LOADER = 'SET_ORG_PIPELINES_LOADER';
export const GET_ORG_WATCHTOWERS = 'GET_ORG_WATCHTOWERS';
export const SET_ORG_WATCHTOWERS_LOADER = 'SET_ORG_WATCHTOWERS_LOADER';
export const GET_ORG_INVITED_USERS = 'GET_ORG_INVITED_USERS';
export const SET_ORG_INVITED_USERS_LOADER = 'SET_ORG_INVITED_USERS_LOADER';
export const SWITCH_ORG = 'SWITCH_ORG';

// events directory
export const SEARCH_ED = 'SEARCH_ED';
export const SET_SEARCH_ED_LOADER = 'SET_SEARCH_ED_LOADER';

// wallets
export const LOAD_WALLETS_RESULT = 'LOAD_WALLETS_RESULT';
export const SET_LOADING_WALLETS = 'SET_LOADING_WALLETS';
export const SET_DELETING_WALLET_BY_ID = 'SET_DELETING_WALLET_BY_ID';
export const OPEN_WALLET_COMPOSER = 'OPEN_WALLET_COMPOSER';
export const CREATE_WALLET = 'CREATE_WALLET';
export const UPDATE_WALLET = 'UPDATE_WALLET';
export const EDIT_WALLET_BY_ID = 'EDIT_WALLET_BY_ID';

// contracts
export const LOAD_CONTRACTS_RESULT = 'LOAD_CONTRACTS_RESULT';
export const SET_LOADING_CONTRACTS = 'SET_LOADING_CONTRACTS';
export const SET_DELETING_CONTRACT_BY_ID = 'SET_DELETING_CONTRACT_BY_ID';
export const OPEN_CONTRACT_COMPOSER = 'OPEN_CONTRACT_COMPOSER';
export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const EDIT_CONTRACT_BY_ID = 'EDIT_CONTRACT_BY_ID';

// functions directory
export const SEARCH_FD = 'SEARCH_FD';
export const SET_SEARCH_FD_LOADER = 'SET_SEARCH_FD_LOADER';

// delivery targets
export const SET_DELIVERY_TARGET_LOADER = 'SET_DELIVERY_TARGET_LOADER';
export const GET_DELIVERY_TARGET_SETTINGS = 'GET_DELIVERY_TARGET_SETTINGS';
export const GET_SINGLE_DELIVERY_TARGET_SETTING =
  'GET_SINGLE_DELIVERY_TARGET_SETTING';
export const SET_CREATE_DELIVERY_TARGET_LOADER =
  'SET_CREATE_DELIVERY_TARGET_LOADER';
export const SET_DELETE_DELIVERY_TARGET_LOADER =
  'SET_DELETE_DELIVERY_TARGET_LOADER';
export const SET_SINGLE_DELIVERY_TARGET_LOADER =
  'SET_SINGLE_DELIVERY_TARGET_LOADER';

// contracts directory
export const SEARCH_CD = 'SEARCH_CD';
export const SET_SEARCH_CD_LOADER = 'SET_SEARCH_CD_LOADER';

// abi guesser
export const GUESS_ABI = 'GUESS_ABI';
export const SET_GUESS_ABI_LOADER = 'SET_GUESS_ABI_LOADER';

//labels
export const ADD_TO_BATCH_LABEL_REQUEST = 'ADD_TO_BATCH_LABEL_REQUEST';
export const FETCH_LABELS = 'FETCH_LABELS';

//labels
export const ADD_TO_BATCH_TOKEN_PRICE_REQUEST =
  'ADD_TO_BATCH_TOKEN_PRICE_REQUEST';
export const FETCH_TOKEN_PRICES = 'FETCH_TOKEN_PRICES';
export const FETCH_POINT_IN_TIME_PRICE = 'FETCH_POINT_IN_TIME_PRICE';

// default chain
export const SET_DEFAULT_CHAIN = 'SET_DEFAULT_CHAIN';

