import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import useAddressRiskStore from 'stateManagement/store/useAddressRiskStore';
import useLabelsStore from 'stateManagement/store/useLabelsStore';
import useTokenMetaDataStore from 'stateManagement/store/useTokenMetaDataStore';
import useTokenPriceStore from 'stateManagement/store/useTokenPriceStore';

const BlockscopeDataProvider = ({ children, orgReducer: { orgId } }) => {
  const fetchPrices = useTokenPriceStore((state) => state.fetchPrices);
  const fetchLabels = useLabelsStore((state) => state.fetchLabels);
  const fetchTokenData = useTokenMetaDataStore((state) => state.fetchTokenData);
  const fetchRiskScores = useAddressRiskStore((state) => state.fetchRiskScores);

  const [loggedInUser, setLoggedInUser] = useState(
    orgId !== null && orgId !== undefined
  );

  useEffect(() => {
    setLoggedInUser(orgId !== null && orgId !== undefined);
  }, [orgId]);

  useEffect(() => {
    const periodicRequest = setInterval(async () => {
      try {
        await fetchLabels();
      } catch (error) {
        console.error(error);
      }
    }, 250);

    return () => clearInterval(periodicRequest);
  }, []);

  useEffect(() => {
    const periodicRequest = setInterval(
      loggedInUser
        ? async () => {
            try {
              await fetchRiskScores();
            } catch (error) {
              console.error(error);
            }
          }
        : async () => {
            return;
          },
      300
    );

    return () => clearInterval(periodicRequest);
  }, [loggedInUser]);

  useEffect(() => {
    const periodicRequest = setInterval(async () => {
      try {
        await fetchTokenData(/* searchExternalData */ true);
        await fetchTokenData(/* searchExternalData */ false);
      } catch (error) {
        console.error(error);
      }
    }, 500);

    return () => clearInterval(periodicRequest);
  }, []);

  useEffect(() => {
    const periodicRequest = setInterval(
      loggedInUser
        ? async () => {
            try {
              await fetchPrices();
            } catch (error) {
              console.error(error);
            }
          }
        : async () => {
            return;
          },
      2500
    );

    return () => clearInterval(periodicRequest);
  }, [loggedInUser]);

  return <Fragment>{children}</Fragment>;
};
BlockscopeDataProvider.propTypes = {
  orgReducer: PropTypes.object,
  children: PropTypes.object,
};
const mapStateToProps = (state) => ({
  orgReducer: state.orgReducer,
});

export default connect(mapStateToProps, {})(BlockscopeDataProvider);

