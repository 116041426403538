export const nativeToken = {
  address: '0x0000000000000000000000000000000000000000',
  name: 'Native Tron',
  symbol: 'TRX',
  tokenType: 'native',
  chain: 'tron',
  decimals: 6,
  coingeckoId: 'tron',
};

