import React, { Fragment, useState } from 'react';
import { Button, Dropdown, Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';

import { ORG_SERVICES } from 'blockscope/static/blockscopeServices';
import {
  BLOCK_BREAKDOWN_POSTHOG,
  TXN_DECODER_POSTHOG,
  WALLET_ACTIVITY_POSTHOG,
} from 'blockscope/static/posthogEvents';
import { QUERY_TYPE_LINKS } from 'blockscope/static/queryTypes';

import { isIterableArray } from 'blockscope/helpers/utils';

import Flex from 'blockscope/components/common/Flex';

import DefaultChainSelector from 'blockscope/components/navbar/top/DefaultChainSelector';

const SearchBox = ({
  autoCompleteItem,
  defaultChain,
  width = '500px',
  autocomplete = true,
}) => {
  const posthog = usePostHog();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [resultItem] = useState(autoCompleteItem);

  const examples = [
    {
      id: 0,
      breadCrumbTexts: ['Transaction', 'Uniswap Swap'],
      url: '/transaction-decoder/ethereum/0x8b75c87493623291d34277545cc2bf003cd86e683f652a2605100b3148354aff',
    },
    {
      id: 1,
      breadCrumbTexts: ['Transaction', 'Flash Loan Attack'],
      url: '/transaction-decoder/ethereum/0xb5c8bd9430b6cc87a0e2fe110ece6bf527fa4f170a4bc8cd032f768fc5219838',
    },
    {
      id: 0,
      breadCrumbTexts: ['Contract', 'UNI Token'],
      url: '/contract-usage/ethereum/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984/?startTime=2023-01-01&endTime=2023-01-31',
    },
    {
      id: 1,
      breadCrumbTexts: ['Contract', 'BAYC NFT Collection'],
      url: '/contract-usage/ethereum/0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D/?startTime=2023-01-01&endTime=2023-01-31',
    },
    {
      id: 0,
      breadCrumbTexts: ['Block', '#17692907'],
      url: '/block-breakdown/ethereum/17692907',
    },
  ];

  const handleSubmit = () => {
    const inputValue = searchInputValue.trim();

    let posthogId = '';
    let posthogObject = {};
    let url = '';

    if (
      inputValue.startsWith('0x') &&
      inputValue.length === 66 &&
      /^[0-9a-fA-F]+$/.test(inputValue.slice(2))
    ) {
      // Valid transaction hash
      url = QUERY_TYPE_LINKS[ORG_SERVICES.TRANSACTION_DECODER]({
        chain: defaultChain,
        transactionHash: inputValue,
      });
      posthogId = TXN_DECODER_POSTHOG.SEARCH;
      posthogObject = { txHash: inputValue, chain: defaultChain };
    } else if (
      inputValue.startsWith('0x') &&
      inputValue.length === 42 &&
      /^[0-9a-fA-F]+$/.test(inputValue.slice(2))
    ) {
      // Valid Ethereum address
      url = QUERY_TYPE_LINKS[ORG_SERVICES.ADDRESS_ACTIVITY]({
        chain: defaultChain,
        address: inputValue,
      });
      posthogId = WALLET_ACTIVITY_POSTHOG.SEARCH;
      posthogObject = {
        address: inputValue,
        chain: defaultChain,
      };
    } else if (/^\d+$/.test(inputValue)) {
      // Valid block number
      url = QUERY_TYPE_LINKS[ORG_SERVICES.BLOCK_BREAKDOWN]({
        chain: defaultChain,
        blockNumber: inputValue,
      });
      posthogId = BLOCK_BREAKDOWN_POSTHOG.SEARCH;
      posthogObject = {
        blockNumber: inputValue, // get from form
        chain: defaultChain,
      };
    }

    if (url) {
      window.location.href = url;
      posthog.capture(posthogId, posthogObject);
      setSearchInputValue('');
      setDropdownOpen(false);
    } else {
      return;
    }
  };

  return (
    <Fragment>
      <DefaultChainSelector />
      <InputGroup>
        <div
          style={{
            alignSelf: 'flex-start',
          }}
        >
          <Dropdown
            onToggle={setDropdownOpen}
            // className='search-box'
            style={{ width }}
            align={'start'}
          >
            <Dropdown.Toggle
              as='div'
              // data-toggle='dropdown'
              aria-expanded={dropdownOpen}
              bsPrefix='toggle'
            >
              {' '}
              <Form.Control
                placeholder='Search Services/Transactions/Addresses/Blocks'
                aria-label='Search'
                value={searchInputValue}
                onChange={({ target }) => setSearchInputValue(target.value)}
                onClick={() => setDropdownOpen(false)}
                style={{ width }}
              />
            </Dropdown.Toggle>
            {autocomplete && (
              <Dropdown.Menu style={{ marginLeft: '63px' }}>
                <div
                  className='scrollbar py-3'
                  style={{ maxHeight: '24rem', width }}
                >
                  {isIterableArray(examples) && (
                    <>
                      {examples.length > 0 && (
                        <Dropdown.Header
                          as='h6'
                          className='px-card pt-0 pb-2 fw-medium'
                        >
                          Examples
                        </Dropdown.Header>
                      )}
                      {examples.map((item) => (
                        <Dropdown.Item
                          as={Link}
                          to={item.url}
                          className='fs--1 px-card py-1 hover-primary'
                          key={item.url}
                        >
                          <Flex alignItems='center'>
                            <FontAwesomeIcon
                              icon='circle'
                              className='me-2 text-300 fs--2'
                            />
                            <div className='fw-normal'>
                              {item.breadCrumbTexts.map(
                                (breadCrumbText, index) => (
                                  <Fragment key={breadCrumbText}>
                                    {breadCrumbText}
                                    {item.breadCrumbTexts.length - 1 >
                                      index && (
                                      <FontAwesomeIcon
                                        icon='chevron-right'
                                        className='mx-1 text-500 fs--2'
                                        transform='shrink 2'
                                      />
                                    )}
                                  </Fragment>
                                )
                              )}
                            </div>
                          </Flex>
                        </Dropdown.Item>
                      ))}
                    </>
                  )}

                  {resultItem && resultItem.length === 0 && (
                    <p className='fs-1 fw-bold text-center mb-0'>
                      No Result Found.
                    </p>
                  )}
                </div>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
        <Button onClick={handleSubmit} style={{ height: '36px' }}>
          <FontAwesomeIcon icon='magnifying-glass' className=' py-0' />
        </Button>
      </InputGroup>
    </Fragment>
  );
};
SearchBox.propTypes = {
  autoCompleteItem: PropTypes.object,
  defaultChain: PropTypes.string,
  width: PropTypes.string,
  autocomplete: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  defaultChain: state.defaultChainReducer.defaultChain,
});

export default connect(mapStateToProps, {})(SearchBox);

