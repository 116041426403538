import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

import BounceSpinLoader from '../blockscopeCommon/loaders/BounceSpinLoader';
import { zodResolver } from '@hookform/resolvers/zod';
import interfaces from 'blockscope-interfaces';
import PropTypes from 'prop-types';
import { z } from 'zod';

import { register_Action } from 'stateManagement/redux/actions/authActions';

const RegistrationFormSchema = z
  .object({
    firstName: z.string().min(1, { message: 'First name is required' }),
    lastName: z.string().min(1, { message: 'Last name is required' }),
    email: z.string().email({ message: 'Invalid email address' }),
    password: interfaces.dragon.user.passwordSchema,
    confirmPassword: z.string(),
    isAccepted: z.literal(true, {
      errorMap: () => ({ message: 'You must accept Terms and Conditions' }),
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

const RegistrationForm = ({
  hasLabel,

  // actions
  register_Action,

  //reducer
  isAuthenticated,
}) => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(RegistrationFormSchema),
    defaultValues: {
      firstName: searchParams.get('firstName') || '',
      lastName: searchParams.get('lastName') || '',
      email: searchParams.get('email') || '',
      password: '',
      confirmPassword: '',
      isAccepted: false,
    },
  });

  // Handler
  const onSubmit = (formData) => {
    register_Action(
      formData.firstName,
      formData.lastName,
      formData.email,
      formData.password
    );
    reset();
    setIsLoading(true);
  };

  if (isAuthenticated) {
    return <Navigate to='/dash' />;
  }

  return isLoading ? (
    <BounceSpinLoader />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>First Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'First Name' : ''}
          name='firstName'
          type='text'
          {...register('firstName')}
        />
        {errors.firstName && (
          <Form.Text className='text-danger'>
            {errors.firstName.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Last Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Last Name' : ''}
          name='lastName'
          type='text'
          {...register('lastName')}
        />
        {errors.lastName && (
          <Form.Text className='text-danger'>
            {errors.lastName.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          name='email'
          type='text'
          {...register('email')}
        />
        {errors.email && (
          <Form.Text className='text-danger'>{errors.email.message}</Form.Text>
        )}
      </Form.Group>

      <Row className='g-2 mb-3'>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            name='password'
            type='password'
            {...register('password')}
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            name='confirmPassword'
            type='password'
            {...register('confirmPassword')}
          />
        </Form.Group>
        {(errors.password || errors.confirmPassword) && (
          <Form.Text className='text-danger'>
            {errors.password?.message || errors.confirmPassword?.message}
          </Form.Text>
        )}
      </Row>

      <Form.Group className='mb-3'>
        <Form.Check type='checkbox' id='acceptCheckbox' className='form-check'>
          <Form.Check.Input
            type='checkbox'
            name='isAccepted'
            {...register('isAccepted')}
          />
          <Form.Check.Label className='form-label'>
            I accept the <Link to='#!'>terms</Link> and{' '}
            <Link to='#!'>privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
        {errors.isAccepted && (
          <Form.Text className='text-danger'>
            {errors.isAccepted.message}
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className='mb-4'>
        <Button className='w-100' type='submit'>
          Register
        </Button>
      </Form.Group>
    </Form>
  );
};

RegistrationForm.propTypes = {
  register_Action: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

RegistrationForm.defaultProps = {
  layout: 'simple',
  hasLabel: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps, { register_Action })(RegistrationForm);
