import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tooltip/dist/react-tooltip.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import BlockscopeDataProvider from 'blockscope/hooks/BlockscopeDataProvider';
import { LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { queryClient } from 'stateManagement/queries/client.js';

import { loadUser_Action } from 'stateManagement/redux/actions/authActions';
import store from 'stateManagement/redux/store';
import { LOGOUT } from 'stateManagement/redux/types';

import setAuthTokenIntoRequestHeader from 'blockscope/utils/setAuthTokenIntoRequestHeader';

import Layout from 'blockscope/layouts/Layout.js';

import {
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';

//Post hog setup
posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
  api_host: 'https://d1qjcptnrubkw7.cloudfront.net',
});

// add base url to all axios requests
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

// if token is found in local storage, set it in your req header
if (localStorage.token) {
  setAuthTokenIntoRequestHeader(localStorage.token);
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

// Echarts Setup
echarts.use([
  LineChart,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
]);

const App = () => {
  const [showDevtools, setShowDevtools] = React.useState(
    process.env.REACT_APP_ENABLE_DEVTOOLS === 'true' || false
  );

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  useEffect(() => {
    store.dispatch(loadUser_Action());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>
          <Router basename={process.env.PUBLIC_URL}>
            <HelmetProvider>
              <BlockscopeDataProvider>
                <Layout />
              </BlockscopeDataProvider>
            </HelmetProvider>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
          {showDevtools && (
            <React.Suspense fallback={null}>
              <ReactQueryDevtoolsProduction />
            </React.Suspense>
          )}
        </QueryClientProvider>
      </PostHogProvider>
    </Provider>
  );
};

export default App;
