export const nativeToken = {
  address: '0x0000000000000000000000000000000000001010',
  name: 'Matic',
  symbol: 'MATIC',
  tokenType: 'native',
  chain: 'polygon',
  decimals: 18,
  coingeckoId: 'matic-network',
};

