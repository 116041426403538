import {
  evmToTronAddress,
  isTronBase58,
  tronToEvmAddress,
} from './tron/convert';

import { nativeToken as nativeArbitrum } from 'blockscope/static/chainData/arbitrum/knownTokens';
import arbitrumValidateExp from 'blockscope/static/chainData/arbitrum/validate';
import { nativeToken as nativeAvalanche } from 'blockscope/static/chainData/avalanche/knownTokens';
import avalancheValidateExp from 'blockscope/static/chainData/avalanche/validate';
import { nativeToken as nativeAvalancheC } from 'blockscope/static/chainData/avalanche_c/knownTokens';
import avalancheCValidateExp from 'blockscope/static/chainData/avalanche_c/validate';
import { nativeToken as nativeBase } from 'blockscope/static/chainData/base/knownTokens';
import baseValidateExp from 'blockscope/static/chainData/base/validate';
import bitcoinValidateExp from 'blockscope/static/chainData/bitcoin/validate';
import { nativeToken as nativeBlast } from 'blockscope/static/chainData/blast/knownTokens';
import blastValidateExp from 'blockscope/static/chainData/blast/validate';
import { nativeToken as nativeBsc } from 'blockscope/static/chainData/bsc/knownTokens';
import bscValidateExp from 'blockscope/static/chainData/bsc/validate';
import { nativeToken as nativeCelo } from 'blockscope/static/chainData/celo/knownTokens';
import celoValidateExp from 'blockscope/static/chainData/celo/validate';
import { nativeToken as nativeEthSepolia } from 'blockscope/static/chainData/ethereum-sepolia/knownTokens';
import ethereumSepoliaValidateExp from 'blockscope/static/chainData/ethereum-sepolia/validate';
import { nativeToken as nativeEth } from 'blockscope/static/chainData/ethereum/knownTokens';
import ethereumValidateExp from 'blockscope/static/chainData/ethereum/validate';
import { nativeToken as nativeFtm } from 'blockscope/static/chainData/fantom/knownTokens';
import fantomValidateExp from 'blockscope/static/chainData/fantom/validate';
import { nativeToken as nativeOptimism } from 'blockscope/static/chainData/optimism/knownTokens';
import optimismValidateExp from 'blockscope/static/chainData/optimism/validate';
import { nativeToken as nativeMatic } from 'blockscope/static/chainData/polygon/knownTokens';
import polygonValidateExp from 'blockscope/static/chainData/polygon/validate';
import { nativeToken as nativeTron } from 'blockscope/static/chainData/tron/knownTokens';
import tronValidateExp from 'blockscope/static/chainData/tron/validate';
import { CHAINS } from 'blockscope/static/supportedChains.js';

const nativeTokens = {
  [CHAINS.ARBITRUM]: nativeArbitrum,
  [CHAINS.AVALANCHE]: nativeAvalanche,
  [CHAINS.BASE]: nativeBase,
  [CHAINS.BSC]: nativeBsc,
  [CHAINS.CELO]: nativeCelo,
  [CHAINS.ETHEREUM]: nativeEth,
  [CHAINS.ETHEREUM_SEPOLIA]: nativeEthSepolia,
  [CHAINS.FANTOM]: nativeFtm,
  [CHAINS.POLYGON]: nativeMatic,
  [CHAINS.OPTIMISM]: nativeOptimism,
  [CHAINS.BLAST]: nativeBlast,
  [CHAINS.TRON]: nativeTron,
  [CHAINS.AVALANCHE_C]: nativeAvalancheC,
};

const validationExp = {
  [CHAINS.ARBITRUM]: arbitrumValidateExp,
  [CHAINS.AVALANCHE]: avalancheValidateExp,
  [CHAINS.BASE]: baseValidateExp,
  [CHAINS.BSC]: bscValidateExp,
  [CHAINS.CELO]: celoValidateExp,
  [CHAINS.ETHEREUM]: ethereumValidateExp,
  [CHAINS.ETHEREUM_SEPOLIA]: ethereumSepoliaValidateExp,
  [CHAINS.FANTOM]: fantomValidateExp,
  [CHAINS.POLYGON]: polygonValidateExp,
  [CHAINS.OPTIMISM]: optimismValidateExp,
  [CHAINS.BITCOIN]: bitcoinValidateExp,
  [CHAINS.BLAST]: blastValidateExp,
  [CHAINS.TRON]: tronValidateExp,
  [CHAINS.AVALANCHE_C]: avalancheCValidateExp,
};

const isValidAddress = (address, chain) => {
  if (!(chain in validationExp)) {
    return false;
  }

  // regex automatically coerces the input to a string so we need to safeguard if the input is not a string
  if (typeof address !== 'string') {
    return false;
  }

  return validationExp[chain].addressRegExp.test(address);
};
const isNativeToken = (address, chain) => {
  if (!(chain in nativeTokens)) {
    return false;
  }

  return nativeTokens[chain].address === address;
};

const isValidTransactionHash = (hash, chain) => {
  if (!(chain in validationExp)) {
    return false;
  }

  return validationExp[chain].transactionRegExp.test(hash);
};

const formatToEvmAddress = (address, fromChain) => {
  switch (fromChain) {
    case CHAINS.TRON:
      return isTronBase58(address)
        ? tronToEvmAddress(address).toLowerCase()
        : address.toLowerCase();
    default:
      return address.toLowerCase();
  }
};

const formatFromEvmAddress = (address, toChain) => {
  if (isValidAddress(address, CHAINS.ETHEREUM)) {
    switch (toChain) {
      case CHAINS.TRON:
        return !isTronBase58(address) ? evmToTronAddress(address) : address;
      default:
        return address.toLowerCase();
    }
  }
  return address.toLowerCase();
};

export default {
  nativeTokens,
  isValidAddress,
  isNativeToken,
  isValidTransactionHash,
  formatToEvmAddress,
  formatFromEvmAddress,
};

