import { SET_DEFAULT_CHAIN } from 'stateManagement/redux/types';

const initialState = {
  defaultChain: 'ethereum',
};

const defaultChainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_CHAIN:
      return {
        ...state,
        defaultChain: action.payload,
      };
    default:
      return state;
  }
};

export default defaultChainReducer;