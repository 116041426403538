import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { setDefaultChain_Action } from 'stateManagement/redux/actions/defaultChainActions';

import ChainSelector from 'blockscope/components/navbar/top/ChainSelector';

const chains = [
  { value: 'ethereum', label: 'Ethereum', logoChain: 'ethereum' },
  { value: 'polygon', label: 'Polygon', logoChain: 'polygon' },
  { value: 'fantom', label: 'Fantom', logoChain: 'fantom' },
];

const DefaultChainSelector = ({
  defaultChainReducer: { defaultChain },
  setDefaultChain_Action,
}) => {
  return (
    <div className='me-1'>
      <ChainSelector
        setChain={setDefaultChain_Action}
        chain={defaultChain}
        chainOptions={chains}
      />
    </div>
  );
};

DefaultChainSelector.propTypes = {
  defaultChainReducer: PropTypes.object.isRequired,
  setDefaultChain_Action: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  defaultChainReducer: state.defaultChainReducer,
});

export default connect(mapStateToProps, { setDefaultChain_Action })(
  DefaultChainSelector
);

