import axios from 'axios';
import { create } from 'zustand';

import { CHAINS } from 'blockscope/static/supportedChains';

const STATUS = {
  SEARCH: 'search',
  FOUND: 'found',
  NOT_FOUND: 'not-found',
};

const initBatchRequest = () => ({
  [STATUS.SEARCH]: new Set(),
  [STATUS.FOUND]: new Set(),
  [STATUS.NOT_FOUND]: new Set(),
});

const initState = {
  addressRiskScores: {
    [CHAINS.ETHEREUM]: new Map(),
    [CHAINS.CELO]: new Map(),
    [CHAINS.POLYGON]: new Map(),
    [CHAINS.FANTOM]: new Map(),
    [CHAINS.BASE]: new Map(),
    [CHAINS.ARBITRUM]: new Map(),
    [CHAINS.OPTIMISM]: new Map(),
    [CHAINS.BSC]: new Map(),
    [CHAINS.AVALANCHE_C]: new Map(),
  },
  batchRequestAddresses: {
    [CHAINS.ETHEREUM]: initBatchRequest(),
    [CHAINS.CELO]: initBatchRequest(),
    [CHAINS.POLYGON]: initBatchRequest(),
    [CHAINS.FANTOM]: initBatchRequest(),
    [CHAINS.BASE]: initBatchRequest(),
    [CHAINS.ARBITRUM]: initBatchRequest(),
    [CHAINS.OPTIMISM]: initBatchRequest(),
    [CHAINS.BSC]: initBatchRequest(),
    [CHAINS.AVALANCHE_C]: initBatchRequest(),
  },
  lastRequest: 0,
};

const useAddressRiskStore = create((set, get) => ({
  ...initState,

  fetchRiskScores: async () => {
    const batchRequests = get().batchRequestAddresses;
    const addressRiskScores = get().addressRiskScores;

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    for (let i = 0; i < Object.keys(batchRequests).length; i++) {
      const chain = Object.keys(batchRequests)[i];

      const batchAddresses = Array.from(batchRequests[chain][STATUS.SEARCH]);

      if (batchAddresses.length > 0) {
        const limitedSearch = [];
        const leftoverSearch = [];

        batchAddresses.forEach((address, index) => {
          if (index < 20) {
            limitedSearch.push(address);
          } else {
            leftoverSearch.push(address);
          }
        });

        const batchAddressesFound = batchRequests[chain][STATUS.FOUND];
        const batchAddressesNotFound = batchRequests[chain][STATUS.NOT_FOUND];

        const body = {
          addresses: batchAddresses,
        };
        const newRiskScores = new Map(addressRiskScores[chain]);
        try {
          const res = await axios.post(
            `/api/v2/risk/chain/${chain}`,
            body,
            config
          );

          // set the risk that were found
          res.data?.dataPayload?.addressRiskScores.forEach((result) => {
            newRiskScores.set(result.address, {
              weighted: result.weightedRiskScore,
              max: result.maxRiskScore,
            });
            batchAddressesFound.add(result.address);
          });

          // find the addresses that were not found and set them to empty array
          const notFoundAddresses = batchAddresses.filter(
            (address) => !newRiskScores.has(address)
          );
          notFoundAddresses.forEach((address) => {
            batchAddressesNotFound.add(address);
          });
        } catch (err) {
          // errorToast('Error fetching labels', LABELS_TOAST_ID.ERROR.FETCH);
        } finally {
          set((state) => ({
            addressRiskScores: {
              ...state.addressRiskScores,
              [chain]: newRiskScores,
            },
            batchRequestAddresses: {
              ...state.batchRequestAddresses,
              [chain]: {
                [STATUS.FOUND]: batchAddressesFound,
                [STATUS.NOT_FOUND]: batchAddressesNotFound,
                [STATUS.SEARCH]: new Set(leftoverSearch),
              },
            },
            lastRequest: Date.now(),
          }));
        }
      }
    }
  },
}));

export const addToBatchAddressRiskRequest = (addresses, chain) => {
  const { batchRequestAddresses, addressRiskScores } =
    useAddressRiskStore.getState();
  addresses.forEach((addr) => {
    const lowerCaseAddress = addr.toLowerCase();
    if (
      chain in batchRequestAddresses &&
      chain in addressRiskScores &&
      !batchRequestAddresses[chain][STATUS.FOUND].has(lowerCaseAddress) &&
      !batchRequestAddresses[chain][STATUS.NOT_FOUND].has(lowerCaseAddress)
    ) {
      batchRequestAddresses[chain][STATUS.SEARCH].add(lowerCaseAddress);
    }
  });

  useAddressRiskStore.setState(() => ({
    batchRequestAddresses,
  }));
};

export const getAddressRiskScore = (address, chain) => {
  const { addressRiskScores } = useAddressRiskStore.getState();
  if (!addressRiskScores[chain]) return null;
  if (!addressRiskScores[chain].has(address.toLowerCase())) return null;
  return addressRiskScores[chain].get(address.toLowerCase());
};

export default useAddressRiskStore;
