import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryClient } from 'stateManagement/queries/client';

import { BLOGS_TOAST_ID } from 'blockscope/static/toastIds';

import {
  axiosErrorToast,
  axiosSuccessToast,
} from 'blockscope/utils/toastHandler';

const getAllBlogsAdminEndpoint = async () => {
  const res = await axios.get(`/api/v2/admin/blog`);
  return res.data.dataPayload.blogs;
};

export const useGetAllBlogsAdmin_Query = () => {
  return useQuery({
    queryKey: ['all-blogs', 'admin'],
    queryFn: () => getAllBlogsAdminEndpoint(),
    cacheTime: 100 * 60 * 1000, // 100 minutes,
    staleTime: 50 * 60 * 1000, // 100 minutes
    retry: false,
    onError: (err) => axiosErrorToast(err, BLOGS_TOAST_ID.ERROR.GET_ALL),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

const getAllBlogsEndpoint = async () => {
  const res = await axios.get(`/api/v2/blog/`);
  return res.data.dataPayload.blogs;
};

export const useGetAllBlogs_Query = () => {
  return useQuery({
    queryKey: ['all-blogs'],
    queryFn: () => getAllBlogsEndpoint(),
    cacheTime: 100 * 60 * 1000, // 100 minutes,
    staleTime: 50 * 60 * 1000, // 100 minutes
    retry: false,
    onError: (err) => axiosErrorToast(err, BLOGS_TOAST_ID.ERROR.GET_ALL),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

const getSingleBlogEndpoint = async (blogId) => {
  const res = await axios.get(`/api/v2/blog/${blogId}`);

  return res.data.dataPayload.blog;
};

export const useGetSingleBlog_Query = (blogId) => {
  if (!blogId) {
    return useQuery({
      queryKey: ['blog'],
      queryFn: () => {
        return null;
      },
    });
  }
  return useQuery({
    queryKey: ['blog', blogId],
    queryFn: () => getSingleBlogEndpoint(blogId),
    cacheTime: 100 * 60 * 1000, // 100 minutes,
    staleTime: 50 * 60 * 1000, // 100 minutes
    retry: false,
    onError: (err) => axiosErrorToast(err, BLOGS_TOAST_ID.ERROR.GET_ALL),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

const getSingleBlogAdminEndpoint = async (blogId) => {
  const res = await axios.get(`/api/v2/admin/blog/${blogId}`);
  return res.data.dataPayload.blog;
};

export const useGetSingleBlogAdmin_Query = (blogId) => {
  if (!blogId) {
    return useQuery({
      queryKey: ['blog', 'admin'],
      queryFn: () => {
        return null;
      },
    });
  }
  return useQuery({
    queryKey: ['blog', 'admin', blogId],
    queryFn: () => getSingleBlogAdminEndpoint(blogId),
    cacheTime: 100 * 60 * 1000, // 100 minutes,
    staleTime: 50 * 60 * 1000, // 100 minutes
    retry: false,
    onError: (err) => axiosErrorToast(err, BLOGS_TOAST_ID.ERROR.GET_ALL),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

export const useCreateBlog_Mutation = () => {
  return useMutation(
    (inputData) =>
      axios
        .post(
          `/api/v2/admin/blog/`,
          { ...inputData },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => res.data.dataPayload),
    {
      onSuccess: async (data) => {
        axiosSuccessToast(data.res, BLOGS_TOAST_ID.SUCCESS.CREATE);

        await queryClient.cancelQueries(['all-blogs', 'admin']);

        const oldBlogsList = queryClient.getQueryData(['all-blogs', 'admin']);

        if (oldBlogsList) {
          oldBlogsList.push(data.newBlogs[0]);
          queryClient.setQueryData(['all-blogs', 'admin'], oldBlogsList);
        }
      },
      onError: (err) => {
        axiosErrorToast(err, BLOGS_TOAST_ID.ERROR.CREATE);
      },
    }
  );
};

export const useUpdateBlog_Mutation = () => {
  return useMutation(
    (inputData) =>
      axios
        .put(
          `/api/v2/admin/blog/${inputData.blogId}`,
          { ...inputData },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => res.data.dataPayload),
    {
      onSuccess: async (data) => {
        axiosSuccessToast(data.res, BLOGS_TOAST_ID.SUCCESS.UPDATE);
      },
      onMutate: async (inputData) => {
        await queryClient.cancelQueries(['blog', 'admin', inputData.blogId]);
        await queryClient.cancelQueries(['blog', inputData.blogId]);

        await queryClient.cancelQueries(['all-blogs', 'admin']);
        await queryClient.cancelQueries(['all-blogs']);

        const adminBlog = queryClient.getQueryData([
          'blog',
          'admin',

          inputData.blogId,
        ]);
        const adminBlogList = queryClient.getQueryData(['all-blogs', 'admin']);
        if (adminBlogList) {
          queryClient.setQueryData(['all-blogs', 'admin'], (adminBlogList) => {
            return adminBlogList.map((blog) => {
              return blog._id === inputData.blogId
                ? { _id: blog._id, ...inputData.newBlog }
                : blog;
            });
          });
        }
        queryClient.setQueryData(
          ['blog', 'admin', inputData.blogId],
          (blog) => {
            console.log('blog', blog);
            return { _id: blog._id, ...inputData.newBlog };
          }
        );

        const blogsList = queryClient.getQueryData(['all-blogs']);
        const blog = queryClient.getQueryData(['blog', inputData.blogId]);
        if (inputData.newBlog.published) {
          if (blogsList) {
            queryClient.setQueryData(['all-blogs'], (blogsList) => {
              return blogsList.map((blog) => {
                return blog._id === inputData.blogId
                  ? { _id: blog._id, ...inputData.newBlog }
                  : blog;
              });
            });
          }
          if (blog) {
            queryClient.setQueryData(
              ['blog', 'admin', inputData.blogId],
              (blog) => {
                return { _id: blog._id, ...inputData.newBlog };
              }
            );
          }
        }
        return {
          adminBlogList,
          blogsList,
          blog,
          adminBlog,
        };
      },
      onError: (err, inputData, context) => {
        axiosErrorToast(err, BLOGS_TOAST_ID.ERROR.UPDATE);

        if (context.adminBlogList) {
          queryClient.setQueryData(
            ['all-blogs', 'admin'],
            context.adminBlogList
          );
        }
        if (context.blogsList) {
          queryClient.setQueryData(['all-blogs'], context.blogsList);
        }
        if (context.adminBlog) {
          queryClient.setQueryData(
            ['blog', 'admin', inputData.blogId],
            context.adminBlog
          );
        }
        if (context.blog) {
          queryClient.setQueryData(['blog', inputData.blogId], context.blog);
        }
      },
    }
  );
};

