import { combineReducers } from 'redux';

import abiGuesserReducer from 'stateManagement/redux/reducers/abiGuesserReducer';
import authReducer from 'stateManagement/redux/reducers/authReducer';
import defaultChainReducer from 'stateManagement/redux/reducers/defaultChainReducer';
import deliverySettingsReducer from 'stateManagement/redux/reducers/deliverySettingsReducer';
import orgReducer from 'stateManagement/redux/reducers/orgReducer';

export default combineReducers({
  authReducer,
  orgReducer,
  deliverySettingsReducer,
  abiGuesserReducer,

  defaultChainReducer,
});

