/* eslint-disable no-console */
import { toast } from 'react-toastify';

import { TOAST_HANDLER } from 'blockscope/static/toastIds';

export const successToast = (msg, id = TOAST_HANDLER.SUCCESS) => {
  toast.success(msg, { theme: 'colored', toastId: id });
};

export const warningToast = (msg, id = TOAST_HANDLER.WARNING) => {
  toast.warning(msg, { theme: 'colored', toastId: id });
};

export const errorToast = (msg, id = TOAST_HANDLER.ERROR) => {
  toast.error(msg, { theme: 'colored', toastId: id });
};

export const axiosErrorToast = (
  err,
  showPlaceholder = true,
  id = TOAST_HANDLER.AXIOS_ERROR
) => {
  // LEAVE THIS HERE DEV LOGGING
  if (process.env.REACT_APP_DOMAIN === 'localhost:3000') {
    console.log(err);
    console.log(err?.response?.data?.message);
  }

  if (err) {
    if (typeof err === 'object' && err?.response?.data?.message) {
      errorToast(err?.response?.data?.message, id);
    } else {
      errorToast(err, id);
    }
  } else if (showPlaceholder === true) {
    toast.error('Something went wrong', {
      toastId: TOAST_HANDLER.AXIOS_ERROR_PLACEHOLDER,
    });
  }
};

export const axiosSuccessToast = (res, id = TOAST_HANDLER.AXIOS_SUCCESS) => {
  successToast(res?.data?.message || 'Success', id);
};
