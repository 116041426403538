import axios from 'axios';
import posthog from 'posthog-js';

import { loadUser_Action } from 'stateManagement/redux/actions/authActions';
import {
  GET_ORG_DETAILS,
  GET_ORG_INVITED_USERS,
  SET_ORG_DETAILS_LOADER,
  SET_ORG_INVITED_USERS_LOADER,
  SWITCH_ORG,
} from 'stateManagement/redux/types';

import { ORG_ACTIONS_TOAST_ID } from 'blockscope/static/toastIds';

import setAuthTokenIntoRequestHeader from 'blockscope/utils/setAuthTokenIntoRequestHeader';
import { axiosErrorToast, successToast } from 'blockscope/utils/toastHandler';

export const selectOrg_Action = (orgId) => async (dispatch) => {
  dispatch({
    type: SET_ORG_DETAILS_LOADER,
    payload: { loadingStatus: true },
  });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let body = { orgId };
  try {
    const res = await axios.post('/api/v2/org/view', body, config);
    const token = await axios.post('/api/v2/org/select', body, config);

    localStorage.setItem('token', token.data.dataPayload.token);
    localStorage.setItem('org', orgId);

    setAuthTokenIntoRequestHeader(localStorage.token);
    posthog.group('org', orgId, {
      orgName: res.data.dataPayload.orgName,
      orgServices: res.data.dataPayload.orgServices,
    });

    dispatch({
      type: GET_ORG_DETAILS,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    // when user cannot access the org, redirect to select org page
    window.location.href = '/select-org';
  }
  dispatch({
    type: SET_ORG_DETAILS_LOADER,
    payload: { loadingStatus: false },
  });
};

export const createOrg_Action = (orgName) => async (dispatch) => {
  dispatch({
    type: SET_ORG_DETAILS_LOADER,
    payload: { loadingStatus: true },
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let body = { orgName };
  try {
    const res = await axios.post('/api/v2/org/create', body, config);

    dispatch({
      type: GET_ORG_DETAILS,
      payload: res.data.dataPayload,
    });
    dispatch(loadUser_Action());
    successToast(
      'Workspace created successfully',
      ORG_ACTIONS_TOAST_ID.SUCCESS.CREATE
    );
  } catch (err) {
    axiosErrorToast(err, ORG_ACTIONS_TOAST_ID.ERROR.CREATE);
  }

  dispatch({
    type: SET_ORG_DETAILS_LOADER,
    payload: { loadingStatus: false },
  });
};

export const cancelInvite_Action = (orgId, email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let body = { orgId, email };
  try {
    const res = await axios.delete(
      '/api/v2/org/invite/recind-invite',
      { data: body },
      config
    );

    successToast(
      'Cancelled Invite',
      ORG_ACTIONS_TOAST_ID.SUCCESS.CANCEL_INVITE
    );

    dispatch(getInvitedUsers_Action(orgId));
  } catch (err) {
    axiosErrorToast(err, ORG_ACTIONS_TOAST_ID.ERROR.CANCEL_INVITE);
    console.log(err);
  }
};

export const resendInvite_Action = (orgId, email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let body = { orgId, email };
  try {
    const res = await axios.post(
      '/api/v2/org/invite/reinvite-user',
      body,
      config
    );
    successToast('Reinvited User', ORG_ACTIONS_TOAST_ID.SUCCESS.RESEND_INVITE);

    dispatch(selectOrg_Action(orgId));
  } catch (err) {
    axiosErrorToast(err, ORG_ACTIONS_TOAST_ID.ERROR.RESEND_INVITE);
    console.log(err);
  }
};

export const inviteUser_Action = (orgId, email, roles) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let body = { orgId, email, roles };
  try {
    const res = await axios.post(
      '/api/v2/org/invite/send-invite',
      body,
      config
    );

    successToast('Invited User', ORG_ACTIONS_TOAST_ID.SUCCESS.INVITE_USER);
    dispatch(getInvitedUsers_Action(orgId));
  } catch (err) {
    console.log(err);

    axiosErrorToast(err, ORG_ACTIONS_TOAST_ID.ERROR.INVITE_USER);
  }
};

export const acceptInvite_Action =
  (orgId, navigateOnSuccess) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let body = { orgId };
    try {
      const res = await axios.put(
        '/api/v2/user/invite/accept-invite',
        body,
        config
      );
      dispatch(selectOrg_Action(orgId));
      navigateOnSuccess(true);
    } catch (err) {
      const { error } = err?.response?.data || {};
      if (error?.data?.service === 'users') {
        axiosErrorToast(err);
      }
      navigateOnSuccess(false);
    }
  };
export const declineInvite_Action = (orgId) => async (dispatch) => {
  let data = { orgId };
  try {
    await axios.delete('/api/v2/user/invite/decline-invite', {
      data,
    });

    dispatch(selectOrg_Action(orgId));
  } catch (err) {
    console.log(err);
  }
};

export const removeUser_Action = (orgId, targetUserId) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let body = { orgId, targetUserId };
  try {
    const res = await axios.put('/api/v2/org/user/remove-user', body, config);

    successToast(
      'User removed successfully',
      ORG_ACTIONS_TOAST_ID.SUCCESS.REMOVE_USER
    );
    dispatch(selectOrg_Action(orgId));
  } catch (err) {
    axiosErrorToast(err, ORG_ACTIONS_TOAST_ID.ERROR.REMOVE_USER);
    console.log(err);
  }
};

export const manageUserRoles_Action =
  (orgId, targetUserId, roles) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let body = { orgId, targetUserId, roles };
    try {
      const res = await axios.put(
        '/api/v2/org/user/manage-user-roles',
        body,
        config
      );

      successToast(
        'User roles saved successfully',
        ORG_ACTIONS_TOAST_ID.SUCCESS.MANAGE_USER_ROLES
      );
      dispatch(selectOrg_Action(orgId));
    } catch (err) {
      axiosErrorToast(err, ORG_ACTIONS_TOAST_ID.ERROR.MANAGE_USER_ROLES);
      console.log(err);
    }
  };

export const getInvitedUsers_Action = (orgId) => async (dispatch) => {
  dispatch({
    type: SET_ORG_INVITED_USERS_LOADER,
    payload: { loadingStatus: true },
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let body = { orgId };
  try {
    const res = await axios.post(
      '/api/v2/org/invite/invite-list',
      body,
      config
    );

    dispatch(selectOrg_Action(orgId));
    dispatch({
      type: GET_ORG_INVITED_USERS,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    console.log(err);
  }
  dispatch({
    type: SET_ORG_INVITED_USERS_LOADER,
    payload: { loadingStatus: false },
  });
};

export const switchOrg_Action = () => async (dispatch) => {
  dispatch({
    type: SWITCH_ORG,
    payload: {},
  });
};

