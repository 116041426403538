import { CHAINS } from 'blockscope/static/supportedChains';

export const nativeToken = {
  address: '0x0000000000000000000000000000000000000000',
  name: 'Native Ether',
  symbol: 'ETH',
  tokenType: 'native',
  chain: CHAINS.ETHEREUM_SEPOLIA,
  decimals: 18,
};
